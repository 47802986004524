import { useEffect, useState } from "react";
import {
  cancelOrder,
  confirmPendingOrder,
  deliverOrderApi,
  dispatchProcessedOrder,
  getOrders,
  orderDetailsApi,
  processConfirmedOrder,
  restoreOrderApi,
} from "../../Apis/orders";
import { Navigate, useNavigate } from "react-router-dom";
import { printInvoice } from "../../utils/toast";
import { toast } from "react-toastify";
import { Button, Divider } from "@mui/material";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlineBlankTwoTone,
  ForkLeftSharp,
  ForkRightOutlined,
  ForkRightRounded,
  MoneyOffOutlined,
  RampRightSharp,
  ReplayOutlined,
  RestartAlt,
  RestartAltOutlined,
  SafetyCheck,
  StopCircle,
  StopCircleOutlined,
  StopCircleSharp,
  TurnRight,
  TurnSharpRight,
  TurnSlightRight,
} from "@mui/icons-material";
import { postDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";

function formatTo12HourTime(isoString) {
  if (!isoString) return "";
  const date = new Date(isoString);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const hours12 = hours % 12 || 12;
  const period = hours < 12 ? "AM" : "PM";
  const formattedTime = `${hours12}:${minutes < 10 ? "0" : ""
    }${minutes} ${period}`;
  return formattedTime;
}

function calculateTimeDifference(date1, date2) {
  if (!date1 || !date2) return "";
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const differenceMs = Math.abs(d1 - d2);
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));
  let formattedDifference = "";
  if (days > 0) {
    formattedDifference += `${days}d `;
  }
  if (hours > 0) {
    formattedDifference += `${hours}h `;
  }
  formattedDifference += `${minutes}m`;
  return formattedDifference;
}
const SingleOrderCard = ({
  item,
  index = 0,
  fetchData,
  setData,
  currentPage,
  setCurrentPageNumber,
  latestRequestTimestamp,
  disableButtons,
}) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  // const [flag,setFlag] = useState(false);
  // function refreshPage() {
  //   latestRequestTimestamp.current = Date.now();
  //   setCurrentPageNumber(0);
  //   setData([]);
  //   fetchData().then();
  // }

  const status = {
    pending: 0,
    confirmed: 0,
    processed: 0,
    dispatched: 0,
    delivered: 0,
    cancelled: 0,
  };
  const mapStatus = async () => {
    if (item.status === "cancelled") {
      status.cancelled = 1;
    }
    if (item.status === "accepted") {
      status.accepted = 2;
      status.riderReached = 1;
    }
    if (item.status === "riderReached") {
      status.accepted = 2;
      status.riderReached = 2;
      status.picked = 1;
    }
    if (item.status === "picked") {
      status.accepted = 2;
      status.riderReached = 2;
      status.picked = 2;
      status.arrivedAtDestination = 1;
    }
    if (item.status === "arrivedAtDestination") {
      status.accepted = 2;
      status.riderReached = 2;
      status.picked = 2;
      status.arrivedAtDestination = 2;
      status.complete = 1;
    }
    if (item.status === "complete") {
      status.accepted = 2;
      status.riderReached = 2;
      status.picked = 2;
      status.arrivedAtDestination = 2;
      status.complete = 2;
    }
  };
  const data = [
    {
      title: "Delivery Accepted",
      status: "accepted",
      value: item.acceptedTime,
    },
    {
      title: "Reached Store",
      status: "riderReached",
      value: item.PickupArrivalTime,
    },
    { title: "Order Picked", status: "picked", value: item.pickupTime },
    {
      title: "Reached Customer",
      status: "arrivedAtDestination",
      value: item.DestinationArrivalTime,
    },
    { title: "Delivered", status: "complete", value: item.completionTime },
  ];

  function formatElapsedTime(startDate) {
    let elapsed = new Date() - new Date(startDate); // Time difference in milliseconds
    let hours = Math.floor(elapsed / (1000 * 60 * 60));
    let minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));

    let formattedTime = "";

    if (hours > 0) {
      formattedTime += hours + "hr ";
    }

    if (minutes > 0 || hours === 0) {
      // Display minutes if there are minutes or if hours are 0
      formattedTime += minutes + "m";
    }

    return formattedTime.trim(); // Remove any trailing whitespace
  }

  // const acceptPendingOrder = async (id) => {
  //   await confirmPendingOrder(id);
  //   refreshPage();
  // };

  // const processOrder = async (id) => {
  //   await processConfirmedOrder(id);
  //   refreshPage();
  // };

  // const dispatchOrder = async (id) => {
  //   await dispatchProcessedOrder(id);
  //   refreshPage();
  // };

  // const restoreOrder = async (id) => {
  //   await restoreOrderApi(id);
  //   refreshPage();
  // };

  // const printOrder = async (id) => {
  //   const result = await orderDetailsApi(item.id);
  //   if (!result.success) {
  //     toast.error(result.message);
  //     return;
  //   }
  //   const temp = result.order;
  //   const data = {
  //     invoice: {
  //       seller: {
  //         phoneNo: result.order.seller.phoneNo,
  //         sellerInformation: {
  //           name: temp.seller.sellerInformation.name,
  //           fullAddress: temp.seller.sellerInformation.fullAddress,
  //         },
  //       },
  //       deliveryCharge: temp.deliveryCharge,
  //       amount: temp.amount,
  //       orderAddress: {
  //         name: temp.address.name,
  //         line1: temp.address.line1,
  //         line2: temp.address.line2,
  //         fullAddress: temp.address.fullAddress,
  //         mobileNo: temp.address.mobileNo,
  //       },
  //       customerMessage: temp.customerMessage,
  //       deliveryDate: formatDate(temp.deliveryDate),
  //       deliveryTime: {
  //         slot: temp.deliveryTime.slot,
  //       },
  //       paymentMode: temp.paymentMode,
  //       id: temp.id,
  //       date: temp ? formatDate(temp.date) : "",
  //       invoiceId: `1-${temp.id}`,
  //     },
  //   };
  //   let tempArray = [];
  //   temp.products.forEach((element) => {
  //     tempArray.push({
  //       hsnCode: element.hsnCode,
  //       recommendedAttribute: element.recommendedAttribute,
  //       sellPrice: element.sellPrice,
  //       quantity: element.quantity,
  //       name: element.name,
  //     });
  //   });
  //   data.invoice.products = tempArray;
  //   printInvoice(data);
  // };

  // const deliverOrder = async (id) => {
  //   await deliverOrderApi(id, otp);
  //   refreshPage();
  // };

  // const cancelorder = async (id) => {
  //   await cancelOrder(id);
  //   refreshPage();
  // };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  }
  const editOrder = () => {
    navigate(`/orderdetails?id=${item.id}&edit=true`);
  };

  const jobFunc = async (id, url) => {
    try {
      const result = await postDataSec(`${baseURL}${url}`, { jobId: id });
      if (result.success) {
        toast.success(result.message);
        window.location.reload();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("error in modifying job");
    }
  };

  const changeType = async (id, type) => {
    try {
      const result = await postDataSec(`${baseURL}jobs/changeType`, {
        type,
        id,
      });
      if (result.success) {
        toast.success(result.message);
        window.location.reload();
      } else {
        toast.err(result.message);
      }
    } catch (error) {
      toast.error("error in changing job type");
    }
  };

  const renderBtn = (status, id, type) => {
    switch (status) {
      case "openForAll":
      case "open":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              endIcon={<CheckBox />}
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="greenBtn"
              onClick={() => jobFunc(id, "job/accept")}
            >
              Accept
            </Button>
            {type === "single" ? (
              <Button
                endIcon={<TurnSlightRight />}
                style={{ background: "#0a3b64" }}
                variant="contained"
                disabled={disableButtons}
                className="greyBtn"
                onClick={() => changeType(id, "route")}
              >
                Move to Route
              </Button>
            ) : (
              <Button
                endIcon={<RestartAltOutlined />}
                style={{ background: "#0a3b64" }}
                variant="contained"
                disabled={disableButtons}
                className="greyBtn"
                onClick={() => changeType(id, "single")}
              >
                Move to Single
              </Button>
            )}
            <Button
              endIcon={<StopCircleSharp />}
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/requestCancellation")}
            >
              Cancel Job
            </Button>
            {/* <Button variant="text" disabled={disableButtons} className="redBtn" onClick={handleCalculatePrice}>
              Calculate Distance
            </Button> */}
          </div>
        );
      case "accepted":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="greenBtn"
              onClick={() => jobFunc(id, "job/reachedPickup")}
            >
              Mark : reached store
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/unassign")}
            >
              Unassign Job
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/requestCancellation")}
            >
              Cancel Job
            </Button>
          </div>
        );
      case "riderReached":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="greenBtn"
              onClick={() => jobFunc(id, "job/pickup")}
            >
              Mark : Order Picked
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/unassign")}
            >
              Unassign Job
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/requestCancellation")}
            >
              Cancel Job
            </Button>
          </div>
        );
      case "picked":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="greenBtn"
              onClick={() => jobFunc(id, "job/reachedDestination")}
            >
              MARK : REACHED DESTINATION
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/unassign")}
            >
              Unassign Job
            </Button>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/requestCancellation")}
            >
              Cancel Job
            </Button>
          </div>
        );
      case "arrivedAtDestination":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="redBtn"
              onClick={() => jobFunc(id, "job/unassign")}
            >
              Unassign Job
            </Button>
            {item.userCancelStatus ? null : (
              <Button
                style={{ background: "#0a3b64" }}
                variant="contained"
                disabled={disableButtons}
                className="greenBtn"
                onClick={() => jobFunc(id, "job/complete")}
              >
                MARK : COMPLETED
              </Button>
            )}
          </div>
        );
      case "complete":
        return <>
          {/* <Button
            style={{ background: "#0a3b64" }}
            variant="contained"
            disabled={disableButtons}
            className="redBtn"
            onClick={() => jobFunc(id, "job/requestCancellation")}
          >
            Cancel Job
          </Button> */}
        </>;
      case "cancelled":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <p>Reason: {item.reasonForCancellation}</p>
            <Button
              style={{ background: "#0a3b64" }}
              variant="contained"
              disabled={disableButtons}
              className="greenBtn"
              onClick={() => jobFunc(id, "job/unassign")}
            >
              Reattempt
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  // const renderStatus = (item) => {
  //   switch (item.status) {
  //     case "pending":
  //       return (
  //         <>
  //           <p className="blacktext">Pending</p>
  //         </>
  //       );
  //     case "confirmed":
  //       return (
  //         <>
  //           <p className="blacktext">Confirmed</p>
  //         </>
  //       );
  //     case "processed":
  //       return (
  //         <>
  //           <p className="blacktext">Processed</p>
  //         </>
  //       );
  //     case "dispatched":
  //       return (
  //         <>
  //           <input className="otpInputBox" value={otp} placeholder="Enter Otp" onChange={(e) => setOtp(e.target.value)} />
  //           <span className="blacktext" style={{ textAlign: "center" }}>
  //             OTP : {item.otp}
  //           </span>
  //         </>
  //       );
  //     case "cancelled":
  //       return (
  //         <>
  //           <p className="blacktext">{item.userCancelStatus === true ? "Cancelled By User" : "Cancelled By Admin"}</p>
  //         </>
  //       );
  //     case "delivered":
  //       return (
  //         <>
  //           <p className="blacktext">Delivered</p>
  //         </>
  //       );
  //     default:
  //       return null;
  //   }
  // };

  function renderDifferences(index) {
    if (index === 0) return null;

    const currentItem = data[index];
    const previousItem = data[index - 1];

    const timeDifference = calculateTimeDifference(
      currentItem.value,
      previousItem.value
    );

    return <span>{timeDifference}</span>;
  }

  const handleCalculatePrice = async () => {
    try {
      const result = await postDataSec(`${baseURL}jobs/calculatePrice`, {
        id: item.orderId,
      });
      if (result.success) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="cardDiv2">
      <table
        style={{
          boxShadow: "none",
          padding: 10,
          borderSpacing: 0,
        }}
      >
        <thead style={{ boxShadow: "none", border: "none" }}>
          <tr style={{ boxShadow: "none", border: "none" }}>
            <th
              style={{
                maxWidth: 32,
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              SNo.
            </th>
            <th
              style={{
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              Order Id
            </th>
            <th
              style={{
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              Job Created On
            </th>

            <th
              style={{
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              Captain Info
            </th>
            <th
              style={{
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              Job Type
            </th>
            <th
              style={{
                padding: "0px 4px",
                textAlign: "left",
                color: "#0a3b64",
                whiteSpace: "nowrap",
              }}
            >
              Payment Details
            </th>
            <th
              style={{
                padding: "0px 4px",
                textAlign: "right",
                color: "#0a3b64",
                width: 160,
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody style={{ boxShadow: "none", border: "0px solid red" }}>
          <tr style={{ boxShadow: "none", border: "none" }} key={item.id}>
            <td
              style={{
                padding: "8px 4px",

                textAlign: "left",
                color: "#0a3b64",
                maxWidth: 15,
              }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/orderdetails?id=${item.id}`)}
              >
                {index + 1}
              </p>
            </td>
            <td
              style={{
                padding: "8px 4px",

                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/orderdetails?id=${item.orderNo}`)}
              >
                {item.orderNo}
              </p>
            </td>
            <td
              style={{
                padding: "8px 4px",

                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              <p>{formatDate(item.created)}</p>
            </td>

            <td
              style={{
                padding: "8px 4px",

                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              <p>{item.partnerName}</p>
              <p>{item.partnerPhoneNo}</p>
            </td>
            <td
              style={{
                padding: "8px 4px",
                textDecorationLine: "underline",
                fontWeight: "bold",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              {item.type === "single" ? "Fast Delivery" : "Route Delivery"}
            </td>
            <td
              style={{
                padding: "8px 4px",

                textAlign: "left",
                color: "#0a3b64",
                flex: 2,
              }}
            >
              <p style={{ fontWeight: "bold" }}>{item.distance / 1000} KM</p>
              <p style={{ fontWeight: "bold" }}>Rs {item.amount + Number(item.bonus?.amount || 0)}</p>
              <p style={{ fontWeight: "bold" }}>
                {(item.duration / 60).toFixed(0)} min
              </p>
              {item?.bonus?.amount && (
                <p style={{ fontWeight: "bold" }}>
                  Bonus Active : Rs {item.bonus.amount}
                </p>
              )}
            </td>
            <td
              style={{
                padding: "8px 4px",
                textAlign: "left",
                color: "#0a3b64",
              }}
            >
              {renderBtn(item.status, item._id, item.type)}
            </td>
          </tr>
        </tbody>
      </table>

      <Divider />
      <div className="timeLineDiv2">
        {item.status !== "cancelled" ? (
          (mapStatus(),
            data.map((_, index) => {
              return (
                <div
                  style={{ flex: index === 0 ? 0.5 : 1, display: "flex" }}
                  key={index}
                >
                  {index !== 0 && (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          border: "0px solid red",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <div style={{ marginBottom: 10, marginRight: 15 }}>
                          {renderDifferences(index)}
                        </div>
                        <div>
                          <svg
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ height: 45, width: 45 }}
                            transform="rotate(90)"
                          >
                            <path
                              d="M12 4V20M12 4L8 8M12 4L16 8"
                              stroke="#000000"
                              strokeWidth="0.672"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  )}
                  <div style={{ flex: 1, display: "flex" }}>
                    <div
                      style={{
                        fontSize: 12,
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor:
                          status[_.status] === 1
                            ? "#aaa"
                            : status[_.status] === 2
                              ? "#0a3b64"
                              : "#aaa",
                        color:
                          status[_.status] === 1
                            ? "white"
                            : status[_.status] === 2
                              ? "white"
                              : "white",
                        borderRadius: 12,
                        maxHeight: 50,
                        minHeight: 50,
                      }}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        {_.title}
                      </span>
                      {status[_.status] === 2 ? (
                        <span style={{ color: "white" }}>
                          {formatTo12HourTime(data[index].value)}
                          {/* {formatElapsedTime(_.value)}) Ago */}
                        </span>
                      ) : null}
                      { }
                    </div>
                  </div>
                </div>
              );
            }))
        ) : (
          <p
            style={{
              padding: 10,
              borderRadius: 12,
              backgroundColor: "#7d7d7d",
              color: "#0a3b64",
            }}
          >
            Cancelled
          </p>
        )}
      </div>
    </div>
  );
};
export default SingleOrderCard;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDataSec, postDataSec } from '../../Apis/fun';
import { baseURL } from '../../config/config';
import { toast } from 'react-toastify';
import { formatDate, formatDateIso } from '../../utils/DateHandler';
import BackSvg from '../../assets/back.svg';
import verifySvg from '../../assets/verified.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem } from '@mui/material';

const DeliveryBoyDetails = () => {
    const { phoneNo } = useParams();
    const navigate = useNavigate();
    const [cashAmount, setCashAmount] = useState(0);
    const [page, setPage] = useState(0);
    const [transactionsList, setTransactionsList] = useState([]);
    const [disableButtons, setDisableButtons] = useState(true);
    const [openModal, setOpenModal] = useState(false);

    const [user, setUser] = useState(null);

    const getDetails = async () => {
        getDataSec(`${baseURL}deliveryBoy?phoneNo=${phoneNo}`).then((result) => {
            if (result.success) {
                setUser(result.details);
                getTransactions(result.details._id);
            } else {
                toast.error(result.message);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const handleCollectCash = async (deliveryPartnerId) => {
        try {
            setOpenModal(false)
            const result = await postDataSec(`${baseURL}deliveryBoy/collectCash`, {
                amount: cashAmount, deliveryPartnerId
            })
            if (result.success) {
                // setDeliveryBoyList([]);
                toast.success(result.message)
                window.location.reload();
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log(err);
            toast.error("ERROR IN COLLECTING CASH");
        }
    }
    const disableAccount = async (disabled) => {
        try {
            const result = await postDataSec(`${baseURL}deliveryBoy/disableAccount`,
                { status: !user.isDisabled, deliveryPartnerId: user._id }
            );
            if (result.success) {
                toast.success(result.message);
                getDetails();
                // setUser({})
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("ERROR IN Disabling account");
        }
    }
    const disableWithdrawal = async (disabled) => {
        try {
            const result = await postDataSec(`${baseURL}deliveryBoy/disableWithdrawal`, { status: !user.withdrawalAllowed, deliveryPartnerId: user._id });
            if (result.success) {
                toast.success(result.message);
                getDetails();
                // setUser({})
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("ERROR IN Disabling account");
        }
    }
    const verifyAccount = async () => {
        try {
            const result = await postDataSec(`${baseURL}deliveryBoy/verifyAccount`, { deliveryPartnerId: user._id });
            if (result.success) {
                toast.success(result.message);
                getDetails();
                // setUser({})
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("ERROR IN Disabling account");
        }
    }

    const getTransactions = async (id) => {
        try {
            const result = await getDataSec(`${baseURL}deliveryBoy/transactions?page=${page}&id=${id}`);
            if (result.success) {
                if (transactionsList.length) {
                    setTransactionsList(prev => ([...prev, ...result.transactions]));
                } else {
                    setTransactionsList(result.transactions);
                }
            } else {
                console.log("No Transactions");
            }
        } catch (error) {
            toast.error("error in getting transactions")
        }
    }

    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (perm.some(permission => permission.name === 'deliveryboy' && permission.read === true || permission.name === "admin")) {
                if (perm.some(permission => permission.name === 'deliveryboy' && permission.modify === true || permission.name === "admin"))
                    setDisableButtons(false);
                else
                    setDisableButtons(true);
            } else {
                navigate("/404")
            }
        }
        getDetails();
    }, [])

    const handleBackBtn = () => {
        navigate(-1);
    }
    useEffect(() => {
        if (user?._id) {
            getTransactions(user._id)
        }
    }, [page])
    if (!user) {
        return <></>
    }

    return (
        <>
            <div id="topBtnsDiv">
                <button id="backBtn" onClick={handleBackBtn}><img src={BackSvg} style={{ width: 20, height: 20 }} />Back</button>
            </div>
            <div className='detailRootContainer' style={{ padding: 20 }}>
                <div className='detailContainer' style={{}}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            <p style={{ fontSize: 32, fontWeight: 700, color: '#444' }}>{user.name} </p>
                            {
                                user.isVerified ?
                                    <img src={verifySvg} width={24} height={24} /> :
                                    <button disabled={disableButtons} className='red' onClick={verifyAccount}>Account Not Verified</button>
                            }
                        </div>
                        <div><p>Phone No : {user.phoneNo}</p></div>
                        <Accordion style={{ width: 350, boxShadow: 'none', padding: 0 }}>
                            <AccordionSummary style={{ width: 350, boxShadow: 'none', padding: 0 }} expandIcon={<ExpandMoreIcon />}><p>Account status : {user.isDisabled ? 'Active' : 'Deactivated'}</p></AccordionSummary>
                            <AccordionDetails className={`${!user.isDisabled ? 'green' : 'red'}`} style={{ padding: 0 }}>
                                <button disabled={disableButtons} onClick={() => disableAccount(user.isDisabled)} className={`${!user.isDisabled ? 'green' : 'red'}`}>{!user.isDisabled ? "Enable  Account" : "Disable Account"}</button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ width: 350, boxShadow: 'none', padding: 0 }}>
                            <AccordionSummary style={{ width: 350, boxShadow: 'none', padding: 0 }} expandIcon={<ExpandMoreIcon />}><p>Withdrawal : {user.withdrawalAllowed ? 'Enabled' : 'Disabled'}</p></AccordionSummary>
                            <AccordionDetails className={`${!user.isDisabled ? 'green' : 'red'}`} style={{ padding: 0 }}>
                                <button disabled={disableButtons} onClick={() => disableWithdrawal(user.withdrawalAllowed)} className={`${!user.withdrawalAllowed ? 'green' : 'red'}`}>{user.withdrawalAllowed ? "Disable Withdrawal" : "Enable Withdrawal"}</button>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10, paddingTop: 40 }}>
                        <div><p>Last Online : {formatDate(user.onlineTime)}</p></div>
                        <div><p>Current Balance : {user.currentBalance}</p></div>
                        <div><p>Floating Cash : {user.floatingCash}</p></div>
                        <div><p>Current Status : {user.status}</p></div>
                        <div style={{ display: 'flex', flex: 1, gap: 10, maxHeight: 40 }}>
                            <input value={cashAmount} type="text" placeholder='Enter Amount' onChange={(e) => { setCashAmount(e.target.value) }} />
                            <button disabled={disableButtons} className='green' onClick={() => setOpenModal(true)}>Collect Cash</button>
                            <Dialog
                                open={openModal}
                                onClose={setOpenModal}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {/* {"Are you sure?"} */}
                                    Collect cash Rs {cashAmount}
                                </DialogTitle>
                                {/* <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Collect cash {cashAmount}
                                    </DialogContentText>
                                </DialogContent> */}
                                <DialogActions>
                                    <Button onClick={() => { setOpenModal(false) }} autoFocus>
                                        Cancel
                                    </Button>
                                    <Button onClick={() => { handleCollectCash(user._id) }} autoFocus>
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </div>

                </div>

                <Accordion style={{ borderRadius: 12, marginTop: 20, border: 0, boxShadow: 'none' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: 32, justifyContent: 'center', alignItems: 'center', display: 'flex', verticalAlign: 'center' }}>
                        <p style={{ fontSize: 32, fontWeight: 700, color: '#444' }}>Pan Card info</p>{
                            user.panCardDetails?.is_valid ?
                                <img src={verifySvg} width={24} height={24} /> :
                                <p>Not Verified</p>
                        } </AccordionSummary>
                    <AccordionDetails style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                        <p>Pan Card Number : {user.panCardDetails?.pan_number}</p>
                        <p>Name on Pan Card : {user.panCardDetails?.name}</p>
                        <p>Father Name : {user.panCardDetails?.father_name}</p>
                        <p>{user.panCardDetails?.image}</p>
                        <p>Verified on : {formatDate(user.panCardDetails?.verifiedDate)}</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ borderRadius: 12, marginTop: 20, border: 0, boxShadow: 'none' }} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: 32, justifyContent: 'center', alignItems: 'center', display: 'flex', verticalAlign: 'center' }}>
                        <p style={{ fontSize: 32, fontWeight: 700, color: '#444' }}>Bank Account Details</p>
                        <img src={verifySvg} width={24} height={24} />
                    </AccordionSummary>
                    <AccordionDetails style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                        <p>Account Name : {user.bankAccountDetails?.account_name}</p>
                        <p>Account Number :{user.bankAccountDetails?.account_number}</p>
                        <p>IFSC Code: {user.bankAccountDetails?.ifsc}</p>
                        <p>Verified on : {formatDate(user.bankAccountDetails?.verifiedDate)}</p>
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ borderRadius: 12, marginTop: 20, border: 0, boxShadow: 'none' }} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: 32, justifyContent: 'center', alignItems: 'center', display: 'flex', verticalAlign: 'center' }}>
                        <p style={{ fontSize: 32, fontWeight: 700, color: '#444' }}>Recent Transactions</p>
                    </AccordionSummary>
                    <AccordionDetails style={{ gap: 10, display: 'flex', flexDirection: 'column' }}>
                        <table>
                            <thead>
                                <tr>
                                    {/* <th>Sr No</th> */}
                                    <th>Id</th>
                                    <th>Date</th>
                                    <th>Remarks</th>
                                    <th>Opening Balance</th>
                                    <th>Closing Balance</th>
                                    <th>Type</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transactionsList.map((item, index) => (
                                        <tr style={{ padding: 10, gap: 30, border: '1px solid #eee', borderRadius: 4 }}>
                                            <td>{item.id}</td>
                                            <td>{formatDateIso(item.created)}</td>
                                            <td>{item.remarks}</td>
                                            {/* <td>{item.type}</td> */}
                                            <td>Rs {item.openingBalance}</td>
                                            <td>Rs {item.closingBalance}</td>
                                            {item.type === "credit" ? <td style={{ color: 'green' }}>Added</td> : <td style={{ color: 'red' }}>Minus(deducted)</td>}
                                            <td>Rs {item.amount}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <Button onClick={() => { setPage(prev => prev + 1); }}>Show More</Button>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}

export default DeliveryBoyDetails;

import React, { useEffect, useState } from "react"
import './DeliveryBoy.css'
import { toast } from "react-toastify";
import { getDataSec, postDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import { useNavigate, useNavigation } from "react-router-dom";
import { Icon, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { OfflinePin, OnlinePrediction, ToggleOnOutlined } from "@mui/icons-material";

const DeliveryBoy = () => {

    const [deliveryBoyList, setDeliveryBoyList] = useState([]);
    const [PhoneNumber, setPhoneNumber] = useState();
    const navigate = useNavigate();
    const [disableButtons, setDisableButtons] = useState(true);

    const handlePhoneNumber = () => {
        navigate(`/deliverypartner/${PhoneNumber}`)
    }

    const getData = async () => {
        try {
            const result = await getDataSec(`${baseURL}deliveryBoy/list`);
            setDeliveryBoyList(result.deliveryBoys)
        } catch (err) {
            console.log(err);
            toast.error('Cannot fetch delivery partner data');
        }
    }

    useEffect(() => {
        let perm = localStorage.getItem("permissions");
        if (perm) {
            perm = JSON.parse(perm);
            if (perm.some(permission => permission.name === 'deliveryboy' && permission.read === true || permission.name === "admin")) {
                if (perm.some(permission => permission.name === 'deliveryboy' && permission.modify === true || permission.name === "admin"))
                    setDisableButtons(false);
                else
                    setDisableButtons(true);
            } else {
                navigate("/404")
            }
        }
        getData();
    }, [])

    return (
        <div style={{ padding: 20, gap: 20, display: 'flex', flex: 1, flexDirection: 'column' }}>
            <div style={{ backgroundColor: 'white', padding: 20, display: 'flex', gap: 20, flex: 1 }}>
                <input className="searchOrder" onKeyDownCapture={(e) => {
                    if (e.nativeEvent.key === "Enter") {
                        handlePhoneNumber();
                    }
                }}
                    placeholder="Search by Mobile No" type="text" onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <button
                    onClick={handlePhoneNumber}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "#ffef03",
                        color: "#000",
                        borderWidth: 1,
                        padding: 10,
                        fontSize: 14,
                        flex: 1,
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderColor: "#e3d400",
                        overflow: "hidden",
                        textAlign: "center",
                        alignItems: "center",
                        maxWidth: 200
                    }}>
                    Search
                </button>
            </div>
            <Table stickyHeader={true} style={{ backgroundColor: 'white', padding: 20, borderRadius: 12, gap: 50, justifyContent: 'space-evenly' }}>
                <TableHead>
                    <TableRow >
                        <TableCell>Sr No.</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Phone No.</TableCell>
                        <TableCell>Current Balance</TableCell>
                        <TableCell>Floating Cash</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryBoyList && deliveryBoyList.length ? deliveryBoyList.map((deliveryBoy, index) => (
                        <TableRow >
                            <TableCell>
                                <p>{index + 1}</p>
                            </TableCell>
                            <TableCell>
                                <p>{deliveryBoy.name}</p>
                            </TableCell>
                            <TableCell>
                                <p>+91 {deliveryBoy.phoneNo}</p>
                            </TableCell>
                            <TableCell>
                                <p>Rs {deliveryBoy.currentBalance}</p>
                            </TableCell>
                            <TableCell>
                                <p>Rs {deliveryBoy.floatingCash}</p>
                            </TableCell>
                            <TableCell>

                                {deliveryBoy.onlineTime && (new Date() - new Date(deliveryBoy.onlineTime)) > 24 * 36e5 ? <p><OnlinePrediction color="green" c />online</p> : <p>offline</p>}
                            </TableCell>
                            <TableCell>
                                <button disabled={disableButtons} style={{ width: 120, height: 40, backgroundColor: '#eee', borderRadius: 12, border: 0, color: 'black', fontWeight: 500 }} onClick={() => { navigate(`/deliverypartner/${deliveryBoy.phoneNo}`) }}>View</button>
                            </TableCell>
                        </TableRow >
                    )) : <></>}
                </TableBody>
            </Table>
        </div >
    )
}

export default DeliveryBoy
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomerByPhoneApi } from "../../Apis/Customer";
import { getOrderWiseReport } from "../../Apis/Dashboard";
import { getOrders, orderCsvApi } from "../../Apis/orders";
// import ExportComponent from "./ExportComponent";
// import "./Orders.css";
import SingleOrderCard from "./JobCard";
import { debounce } from "lodash";
import { getJobs } from "../../Apis/jobs";
import { toast } from "react-toastify";
import { getDataSec } from "../../Apis/fun";
import { baseURL } from "../../config/config";
import DateTimePicker from "react-datetime-picker";
import BonusModal from "./BonusModal";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
  ToggleButton,
} from "@mui/material";

const Jobs = () => {
  const [searchDisable, searchDisable_] = useState(false);
  const [jobType, setJobType] = useState("open");
  const [softFilter, setSoftFilter] = useState("all");
  const [data, setData] = useState();
  const [visibleCards, setVisibleCards] = useState([]);
  const today = new Date();
  today.setDate(today.getDate() - 7); // Subtract 7 days from today's date
  const sevenDaysAgo = new Date(today); // Create a new Date object with the date 7 days ago
  const formattedSevenDaysAgo = sevenDaysAgo.toISOString().split("T")[0];
  const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);
  const [fromDate, setFromDate] = useState(formattedSevenDaysAgo);
  const [orderCount, setOrderCount] = useState();
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [noMoreOrders, setNoMoreOrders] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [disableButtons, setDisableButtons] = useState(true);
  const [activeBonus, setActiveBonus] = useState(null);
  const [showBonusModal, setShowBonusModal] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = (jobType) => {
    setJobType(jobType);
  };

  const getOrderCount = async () => {
    try {
      const response = await getDataSec(`${baseURL}jobcount`);
      if (response) {
        let temp = response.orderCount;
        setOrderCount(temp);
      }
    } catch (error) {
      console.error("Error fetching order count:", error);
    }
  };

  useEffect(() => {
    getActiveBonus();
    let perm = localStorage.getItem("permissions");
    if (perm) {
      perm = JSON.parse(perm);
      if (
        perm.some(
          (permission) =>
            (permission.name === "orders" && permission.read === true) ||
            permission.name === "admin"
        )
      ) {
        if (
          perm.some(
            (permission) =>
              (permission.name === "orders" && permission.modify === true) ||
              permission.name === "admin"
          )
        )
          setDisableButtons(false);
        else setDisableButtons(true);
      } else {
        navigate("/404");
      }
    }
    getOrderCount().then();
  }, []);

  const loadMoreOrders = async () => {
    if (loadingOrders || !data) return;
    setLoadingOrders(true);
    try {
      const response = await getJobs(jobType, currentPageNumber);
      setCurrentPageNumber(currentPageNumber + 1);
      if (response.jobs === undefined || !response.jobs.length) {
        setNoMoreOrders(true);
      } else {
        const tempData = response.jobs.filter((item) => (softFilter === "fast") ? item.type === "single" : (softFilter === "route") ? item.type === "route" : true)
        console.log(data, tempData)
        setVisibleCards((prevData) => ([...prevData, ...tempData]));
      }
    } catch (error) {
      console.error("Error loading more orders:", error);
    }
    setLoadingOrders(false);
  };
  const handleScroll = debounce(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    if (
      windowHeight + scrollTop >= documentHeight - 200 &&
      !noMoreOrders &&
      !loadingOrders
    ) {
      loadMoreOrders();
    }
  }, 300);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fetchData = async () => {
    if (!jobType) return;
    setNoMoreOrders(false);
    setVisibleCards(null);
    setLoadingOrders(true);
    const requestTimestamp = Date.now();
    try {
      const result = await getJobs(jobType);
      if (Math.abs(requestTimestamp - latestRequestTimestamp.current) <= 50) {
        if (!result.success) {
          setNoMoreOrders(true);
        } else {
          setData(result);
          if (softFilter === "fast") {
            setVisibleCards(
              result.jobs.filter((item) => item.type === "single")
            );
          } else if (softFilter === "route") {
            setVisibleCards(
              result.jobs.filter((item) => item.type === "route")
            );
          } else {
            setVisibleCards(result.jobs);
          }
          setCurrentPageNumber(1);
        }
      } else {
        console.log("validation failed", result);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }

    // getOrderCount().then();

    setLoadingOrders(false);
  };

  const getActiveBonus = async () => {
    try {
      const response = await getDataSec(`${baseURL}showActiveBonus`);
      if (response.success) {
        setActiveBonus(response.bonus);
      }
    } catch (error) {
      console.log("error in fetching active bonus", error);
      toast.error("CANNOT FETCH ACTIVE BONUS");
    }
  };

  // Define a ref to store the timestamp of the latest request
  const latestRequestTimestamp = useRef(null);
  useEffect(() => {
    latestRequestTimestamp.current = Date.now();
    setCurrentPageNumber(0);
    setData([]);
    fetchData().then();
  }, [jobType, toDate, fromDate]);

  const handlePhoneNumber = async () => {
    if (PhoneNumber.length === 10) {
      try {
        const res = await getCustomerByPhoneApi(PhoneNumber);
        if (res && res.users && res.users._id)
          navigate(`/customerdetails?id=${res.users._id}`);
        else {
          alert("Not found.");
        }
      } catch (error) {
        console.log(error);
        alert("Not found.");
      }
    } else navigate(`/orderdetails?id=${PhoneNumber}`);
  };

  useEffect(() => {
    if (!data) return;
    let temp = data?.jobs;
    if (softFilter === "fast")
      temp = temp.filter((item) => item.type === "single");
    else if (softFilter === "route")
      temp = temp.filter((item) => item.type === "route");
    setVisibleCards(temp);
    // setVisibleCards(data.jobs.filter((item) => item.type === "single"));
  }, [softFilter]);

  // const downloadCsv = async () => {
  //   try {
  //     setDisableExport(true)
  //     const res = await orderCsvApi(orders, fromDate, toDate);
  //     if (res) {
  //       const blob = new Blob([res], { type: 'text/csv' });
  //       const url = window.URL.createObjectURL(blob);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'orders.csv');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     }
  //     setDisableExport(false)
  //   } catch (error) {
  //     console.log(error, "at Customer.js")
  //   }
  // }
  return (
    <div>
      <div id="FilterOrdersDiv">
        <div id="filterNav">
          <input
            className="searchOrder"
            onKeyDownCapture={(e) => {
              if (e.nativeEvent.key === "Enter") {
                handlePhoneNumber();
              }
            }}
            placeholder="Search by Mobile or order ID"
            type="text"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <div id="filterNav">
            <button
              onClick={handlePhoneNumber}
              style={{
                cursor: !searchDisable ? "pointer" : "default",
                backgroundColor: searchDisable ? "#ddd" : "#ffef03",
                color: searchDisable ? "#aaa" : "#000",
                borderWidth: searchDisable ? 0 : 1,
                padding: 10,
                fontSize: 14,
                flex: 1,
                borderRadius: 10,
                borderStyle: "solid",
                borderColor: "#e3d400",
                overflow: "hidden",
                textAlign: "center",
                alignItems: "center",
                maxWidth: 200,
              }}
            >
              Search
            </button>
            {activeBonus ? (
              <button
                style={{
                  width: 120,
                  height: 40,
                  backgroundColor: "rgba(0,240,0,0.2)",
                  border: "1px solid green",
                  borderRadius: 10,
                }}
                onClick={() => {
                  setShowBonusModal(true);
                }}
              >
                Show Active Bonus
              </button>
            ) : (
              <button
                style={{
                  width: 120,
                  height: 40,
                  backgroundColor: "rgba(0,240,0,0.2)",
                  border: "1px solid green",
                  borderRadius: 10,
                }}
                onClick={() => {
                  setShowBonusModal(true);
                }}
              >
                Activate Bonus
              </button>
            )}
          </div>
        </div>
        {/* <div id="dateNav">
          <div style={{ display: "flex", gap: 20, width: "60%", justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                flex: 0.2,
                flexDirection: "column",
                display: "flex",
                paddingLeft: 5,
                paddingRight: 5,
                border: "2px solid #e6e6e6",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                paddingBottom: 5,
              }}>
              <label
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "flex-start",
                }}>
                Start Date
              </label>
              <input
                style={{
                  borderWidth: 0,
                  outlineWidth: 1,
                  padding: 0,
                  textAlign: "center",
                }}
                type="date"
                value={fromDate}
                label="Start Date"
                onChange={(e) => {
                  setFromDate(e.target.value);

                }}
                className="date-picker-input"
                placeholder="Start date"
              />
            </div>
            <div
              style={{
                flex: 0.2,
                flexDirection: "column",
                display: "flex",
                border: "2px solid #e6e6e6",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                paddingLeft: 5,
                paddingRight: 5,
                paddingBottom: 5,
              }}>
              <label
                style={{
                  textAlign: "center",
                  fontSize: 12,
                  justifyContent: "flex-start",
                }}>
                End Date
              </label>
              <input
                style={{
                  borderWidth: 0,
                  padding: 0,
                  outlineWidth: 0,
                  textAlign: "center",
                }}
                type="date"
                value={toDate}
                onChange={(e) => {
                  setToDate(e.target.value);
                }}
                className="date-picker-input"
                placeholder="End date"
              />
            </div>
          </div>
          <div>
            <button className="ExportButton" disabled={disableExport} onClick={downloadCsv}>EXPORT</button>
          </div>
        </div> */}
      </div>

      {showBonusModal && (
        <BonusModal
          setShowBonusModal={setShowBonusModal}
          activeBonus={activeBonus}
        />
      )}

      <div id="OrdersListDiv">
        <div className="orderNav">
          <h5>Jobs</h5>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={softFilter}
              onChange={(e) => {
                setSoftFilter(e.target.value);
              }}
              sx={{ display: "flex", flexDirection: "row" }}
              name="radio-buttons-group"
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel value="fast" control={<Radio />} label="Fast" />
              <FormControlLabel
                value="route"
                control={<Radio />}
                label="Route"
              />
            </RadioGroup>
          </FormControl>
          <div className="orderNavButtons">
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "open" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("open")}
            >
              Open jobs {orderCount && `(${orderCount[0]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "accepted" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("accepted")}
            >
              Accepted {orderCount && `(${orderCount[1]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "riderReached" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("riderReached")}
            >
              Reached Store {orderCount && `(${orderCount[2]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "picked" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("picked")}
            >
              Picked {orderCount && `(${orderCount[3]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={
                jobType === "arrivedAtDestination" ? "active1" : "normal1"
              }
              onClick={() => handleButtonClick("arrivedAtDestination")}
            >
              Reached Destination {orderCount && `(${orderCount[4]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "complete" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("complete")}
            >
              Completed {orderCount && `(${orderCount[5]?.count})`}
            </button>
            <button
              style={{ padding: "10px 30px" }}
              className={jobType === "cancelled" ? "active1" : "normal1"}
              onClick={() => handleButtonClick("cancelled")}
            >
              Cancelled {orderCount && `(${orderCount[6]?.count})`}
            </button>
          </div>
        </div>
        <div></div>

        {/* <table>
          <thead>
            <tr className="headerRow">
              <th>Sr</th>
              <th>Order ID</th>
              <th>Date</th>
              <th>Customer Details</th>
              <th>Transaction Details</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table> */}
        {visibleCards?.length > 0 &&
          visibleCards.map((item, index) => (
            <SingleOrderCard
              disableButtons={disableButtons}
              key={index}
              setCurrentPageNumber={setCurrentPageNumber}
              setData={setData}
              latestRequestTimestamp={latestRequestTimestamp}
              fetchData={fetchData}
              item={item}
              index={index}
            />
          ))}
        {noMoreOrders && (
          <p style={{ textAlign: "center" }}>❌No More Jobs</p>)}
        {loadingOrders && <div className="loader"></div>}
      </div>
    </div>
  );
};

export default Jobs;
